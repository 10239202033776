import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { AgentModal } from './AgentModal';
import { getAllAgents_Api, removeUser_Api } from '../../Apiprovider/Apiprovider';
import Swal from 'sweetalert2'

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  
  useEffect(() => {
    loadAgents();
  }, []);

  const loadAgents = async () => {
    const agentsRes = await getAllAgents_Api();
    if(agentsRes.state !== 'success') return;
    console.log(agentsRes);
    setUserList(agentsRes.data);
  }

  const removeAgent = async (id) => {
    Swal.fire({
      title: "Do you want to remove this Argent?",
      showCancelButton: true,
      confirmButtonText: "Yes",
  }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          const userApi = await removeUser_Api(id);
          if (userApi.state !== 'success') return;
          loadAgents();
      } else {
          // Swal.fire("Changes are not saved", "", "info");
          console.log('cancelled');
      }
  });
    
  }

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ width: '100%', height: '100%', textAlign: 'right' }}>
            <Button variant="contained" color="success" onClick={() => {
              console.log(row)
              setUserData(row);
              setOpenModal(true);
            }}>
              Edit
            </Button>
            <Button onClick={() => { removeAgent(row.id) }} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  const handleCloseModal = (reloadable) => {
    setUserData(null);
    setOpenModal(false);
    if(reloadable){
      loadAgents();
    }
  }

  const AddNewAgent = () => {
    setUserData(null);
    setOpenModal(true);
  }

  return (
    <Box m="20px" width={'100%'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Header title="Users" subtitle="Managing the Agents Members" />
        <Button onClick={() => AddNewAgent()} variant='contained' color='success'>New Agent</Button>
      </Box>
      
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={userList} columns={columns} />
      </Box>
      <AgentModal 
        open={openModal}
        handleClose={handleCloseModal}
        agentData={userData}
      />
    </Box>
  );
};

export default Team;
