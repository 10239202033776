import { USERDATA, ISLOGGEDIN } from "../constants/main";

export const userData_Store = (params) => {
    return (dispatch) =>
        dispatch({type: USERDATA, payload: params})
}

export const isLoggedIn_Store = (params) => {
    return (dispatch) => 
        dispatch({type: ISLOGGEDIN, payload: params});
}
