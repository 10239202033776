import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { deleteAirport_Api, getAllAirportsApi } from "../../Apiprovider/Apiprovider"
import { AirportModal } from './AirportModal';
import Swal from 'sweetalert2'

const AirPorts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [portsData, setPortsData] = useState([])
  const [airportData, setAirportData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = (reloadable) => {
    setAirportData(null);
    setModalOpen(false);
    if (reloadable) {
      loadAirPortsData()
    }

  }

  const openNewAirPortModal = () => {
    setAirportData(null);
    setModalOpen(true);
  }

  useEffect(() => {
    loadAirPortsData()
  }, [])

  const loadAirPortsData = async () => {
    const apiRes = await getAllAirportsApi();
    console.log(apiRes);
    if (apiRes.state !== 'success') return;
    const resPorts = apiRes.data;
    setPortsData(resPorts);
  }

  const deleteAirport = async (id) => {
    console.log(id);
    Swal.fire({
      title: "Do you want to remove this Airport?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await deleteAirport_Api(id);
        loadAirPortsData();
      } else {
        // Swal.fire("Changes are not saved", "", "info");
        console.log('cancelled');
      }
    });

  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "alertnetive",
      headerName: "Alternative Name",
      headerAlign: "left",
      align: "left",
    },

    {
      field: "country",
      headerName: "Country",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "city",
      headerName: "City",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "iata_code",
      headerName: "IATA Code",
      headerAlign: "left",
      align: "left",
    },
    {
      field: 'id',
      flex: 1,
      headerName: 'Action',
      renderCell: ({ row }) => {
        return (
          <Box sx={{ width: '100%', height: '100%', textAlign: 'right' }}>
            <Button variant="contained" color="success" onClick={() => {
              console.log(row)
              setAirportData(row);
              setModalOpen(true);
            }}>
              Edit
            </Button>
            <Button onClick={() => { deleteAirport(row.id) }} variant="contained" color="error">
              Delete
            </Button>
          </Box>

        );
      },
    }
  ];

  return (
    <Box m="20px" width={'100%'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Header title="AirPorts" subtitle="See the AirPorts" />
        <Button onClick={() => { openNewAirPortModal() }} variant='contained' color='success' >Add AirPort</Button>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={portsData} columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
      <AirportModal
        open={modalOpen}
        handleClose={handleClose}
        airport={airportData}
      />
    </Box>
  );
};

export default AirPorts;
