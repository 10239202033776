import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import configureStore from './store/';
import { Provider } from 'react-redux'
import { mainConst } from './store/constants/main';

const InitialData = mainConst;

InitialData.userData = localStorage.getItem('userData')? JSON.parse(localStorage.getItem('userData')) : {};  
InitialData.isLoggedIn = localStorage.getItem('userData')? true : false;  
ReactDOM.render(
  <Provider store={configureStore(InitialData)} serverState={InitialData}>
      <Router>
        <App />
      </Router>
  </Provider>,
  
  document.getElementById('root')
);
