import React, { useState, useContext, useEffect } from 'react';
import { Box, IconButton, Grid, useTheme, Typography, Card, Button } from "@mui/material";

import { ColorModeContext, tokens } from "../../theme";
import './dashboard.css'

import InsightsIcon from '@mui/icons-material/Insights';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanIcon from '@mui/icons-material/Lan';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

import Header from "../../components/Header";
import StatBox from "../../components/StatBox";

import PieChart from '../../components/PieChart';
import BarChart from "../../components/BarChart"
import { Stack } from '@mui/system';

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AirlinesIcon from '@mui/icons-material/Airlines';
import analysticPng from '../../assets/analytics.png'
import { getDashboard_Api } from '../../Apiprovider/Apiprovider';

let mobileScreenDetect = false

const cardBoxStyle = {
  padding: 3,
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px'
}

const Dashboard = () => {

  const [isSidebar, setIsSidebar] = useState(!mobileScreenDetect);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [submenuId, setSubmenuId] = useState(1);
  const [data, setData] = useState(null);
  useEffect(() => {
    loadDashboardData()
  }, [])
  const loadDashboardData = async () => {
    const apiRes = await getDashboard_Api();
    console.log(apiRes);
    if (apiRes.state != 'success') return;
    setData(apiRes.data);
  }

  const subMenuClicked = (menuId) => {
    setSubmenuId(menuId);
    setIsSidebar(true);
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display={'flex'} alignItems={'center'}>
          {mobileScreenDetect &&
            < DarkModeOutlinedIcon className='mx-2' />
          }

          <Header title="Dashboard" subtitle="Welcome to this dashboard" />
        </Box>

        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>
      <Box>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={6} height={'100%'}>
            <Box
              backgroundColor={colors.primary[400]}
              sx={cardBoxStyle}
              height={'250px'}
            >
              <Typography variant='h3' fontWeight={'bold'} >
                Analyitics
              </Typography>
              <Stack direction={'row'} spacing={1} >
                <Box width={'100%'}>
                  <Stack height={'100%'} direction={'column'} justifyContent={'space-between'}>
                    <Box></Box>
                    <Box width={'100%'}>
                      <Typography variant='h4'>Traffics</Typography>
                      <Grid container spacing={2} padding={1}>
                        <Grid item xs={6} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                          <Typography variant='h4' fontWeight={'bold'} sx={{ backgroundColor: colors.primary[200], paddingY: 0.5, paddingX: 2, borderRadius: '6px' }}
                          >12
                          </Typography>
                          <Typography variant='h5' fontWeight={'bold'} marginX={1}>Tickets</Typography>
                        </Grid>
                        <Grid item xs={6} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                          <Typography variant='h4' fontWeight={'bold'} sx={{ backgroundColor: colors.primary[200], paddingY: 0.5, paddingX: 2, borderRadius: '6px' }}
                          >12
                          </Typography>
                          <Typography variant='h5' fontWeight={'bold'} marginX={1}>Tickets</Typography>
                        </Grid>
                        <Grid item xs={6} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                          <Typography variant='h4' fontWeight={'bold'} sx={{ backgroundColor: colors.primary[200], paddingY: 0.5, paddingX: 2, borderRadius: '6px' }}
                          >12
                          </Typography>
                          <Typography variant='h5' fontWeight={'bold'} marginX={1}>Tickets</Typography>
                        </Grid>
                        <Grid item xs={6} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                          <Typography variant='h4' fontWeight={'bold'} sx={{ backgroundColor: colors.primary[200], paddingY: 0.5, paddingX: 2, borderRadius: '6px' }}
                          >12
                          </Typography>
                          <Typography variant='h5' fontWeight={'bold'} marginX={1}>Tickets</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
                <Box width={'100%'} textAlign={'right'}>
                  <img src={analysticPng} alt='analystic' height={150} />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} height={'100%'}>
            <Box
              backgroundColor={colors.primary[400]}
              sx={cardBoxStyle}
              height={'250px'}
            >
              <Stack height={'100%'} direction={'column'} justifyContent={'space-between'}>
                <Typography variant='h3' fontWeight={'bold'} >
                  Statistics
                </Typography>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={3} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                      <Box backgroundColor={'#e9e7fd'} padding={1} borderRadius={'5px'} lineHeight={1}>
                        <SupervisorAccountIcon sx={{ color: '#7367f0' }} />
                      </Box>
                      <Box margin={2}>
                        <Typography variant='h5' fontWeight={'bold'}>{data?.users}</Typography>
                        <Typography variant='body1'>Users</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                      <Box backgroundColor={'#d6f4f8'} padding={1} borderRadius={'5px'} lineHeight={1}>
                        <AirplaneTicketIcon sx={{ color: '#00bad1' }} />
                      </Box>
                      <Box margin={2}>
                        <Typography variant='h5' fontWeight={'bold'}>{data?.tickets}</Typography>
                        <Typography variant='body1'>Tickets</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                      <Box backgroundColor={'#ffe2e3'} padding={1} borderRadius={'5px'} lineHeight={1}>
                        <ConnectingAirportsIcon sx={{ color: '#ff4c51' }} />
                      </Box>
                      <Box margin={2}>
                        <Typography variant='h5' fontWeight={'bold'}>{data?.airports}</Typography>
                        <Typography variant='body1'>Air Ports</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} display={'flex'} justifyContent={'start'} alignItems={'center'}>
                      <Box backgroundColor={'#ddf6e8'} padding={1} borderRadius={'5px'} lineHeight={1}>
                        <AirlinesIcon sx={{ color: '#28c76f' }} />
                      </Box>
                      <Box margin={2}>
                        <Typography variant='h5' fontWeight={'bold'}>{data?.airlines}</Typography>
                        <Typography variant='body1'>Air Lines</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>

            </Box>
          </Grid>
          <Grid item xs={8} >
            <Box backgroundColor={colors.primary[400]}
              sx={cardBoxStyle} height={'50vh'}>
              <BarChart />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              backgroundColor={colors.primary[400]}
              sx={cardBoxStyle} height={'50vh'}>
              <PieChart />
            </Box>
          </Grid>
        </Grid>
      </Box>

    </Box >
  );
};

export default Dashboard;
