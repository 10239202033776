import { Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material"
import authBg from '../../assets/authBg.jpg';
import { ColorModeContext, tokens } from "../../theme";
import { useContext, useState } from "react";
import { login_Api } from "../../Apiprovider/Apiprovider";
import { isLoggedIn_Store, userData_Store } from "../../store/actions/mainAction";
import { useDispatch } from "react-redux";
export const Login = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.validity.valid) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true)
    if (e.target.checkValidity()) {

      const resLog = await login_Api({ email: email, password: password });
      console.log('email Logged In', resLog);
      if (resLog.state === "success") {
        let tmpUser = resLog.data;
        tmpUser.tokens = resLog.data.token;
        localStorage.setItem('userData', JSON.stringify(tmpUser));
        dispatch(userData_Store(tmpUser));
        dispatch(isLoggedIn_Store(true));
        setTimeout(() => { window.location.href = '/'; }, 500)
      } else {
        setIsSubmitted(false)
      }
    } else {
      setIsSubmitted(false)
      alert("Form is invalid! Please check the fields...");
    }
  }
  return (
    <main className="content" >
      <Box width={'100%'} height={'100vh'} display={'flex'}
        sx={{
          backgroundImage: `url(${authBg})`,
          backgroundColor: '#010822',
          backgroundOrigin: 'center',
          backgroundSize: 'auto 100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Box width={'50%'}>

        </Box>
        <Box width={'50%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Box padding={3}>
            <Typography variant="h2" marginBottom={4}>{'Login to Admin'}</Typography>
            <Grid component={'form'} onSubmit={handleLoginSubmit} marginBottom={2} container spacing={3}>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-email"
                  label={'Email'} type='email' variant="outlined"
                  value={email}
                  error={emailError}
                  onChange={onEmailChange}
                  required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="outlined-password"
                  value={password}
                  error={passwordError}
                  label={'Password'} type='password' variant="outlined"
                  onChange={onPasswordChange}
                  required />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmitted} fullWidth variant="contained" color={'success'}
                  type="submit"
                  sx={{ fontWeight: 600 }}
                >Login</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </main>
  )
}