import axios from 'axios'

export const BASE_BACKEND_URL = process.env.REACT_APP_BACKEND_BASE_URL
const redirectlogin = () => {
  window.location.href = '/login';  
}
const getJWTToken = () => {
  const strUser = localStorage.getItem('userData');
  if(strUser){
    const userData = JSON.parse(strUser);
    console.log('using Data', userData);
    return `Bearer ${userData.tokens}`;
  }else{
    window.location.href = '/login';  
    return '';
  }
  
}
export const getDashboard_Api = async () => {
  console.log('dashboardAPi');
  const tokenData = getJWTToken();
  let resultState = {state: '', data: {}};
  const apiUrl = `${BASE_BACKEND_URL}/data/getDashboard`;
  await axios({
    method: 'get',
    url: apiUrl,
    headers: {Authorization: tokenData}
  }).then(function(response){
    resultState.state = 'success';
    resultState.data = response.data;
  }).catch(function(err) {
    console.log('err', err);
    resultState.state = 'error';
    resultState.data = err.response?.data? err.response.data.message : err.message;
    // window.toastr.error('Email or Password is not matched');
  })

  return resultState;
}

export const removeUser_Api = async (id) => {
  let resultState = {state: '', data: {}};
  const tokenData = getJWTToken();
  const apiUrl = `${BASE_BACKEND_URL}/users/${id}`;
  await axios.delete(apiUrl, {headers: {Authorization: tokenData}}).then(function (response) {
    resultState.state = 'success';
    resultState.data = response.data;
  }).catch(function(err) {
    console.log('err', err);
    resultState.state = 'error';
    resultState.data = err.response?.data? err.response.data.message : err.message;
  })

  return resultState;
}

export const currentUser = async (loginInfo) => {
  const tokenData = getJWTToken();
  let resultState = {state: '', data: {}};
  const apiUrl = `${BASE_BACKEND_URL}/users/current`;
  await axios({
    method: 'get',
    url: apiUrl,
    data: {user: loginInfo},
    headers: {Authorization: tokenData}
  }).then(function(response){
    resultState.state = 'success';
    resultState.data = response.data;
  }).catch(function(err) {
    console.log('err', err);
    resultState.state = 'error';
    // resultState.data = err.response?.data? err.response.data.message : err.message;
    // window.toastr.error('Email or Password is not matched');
  })

  return resultState;
  
}

export const login_Api = async (loginInfo) => {
  let resultState = {state: '', data: {}};
  const apiUrl = `${BASE_BACKEND_URL}/users/authenticate`;

  const data = {
    email: loginInfo.email,
    password: loginInfo.password
  };

  await axios({
    method: 'post',
    url: apiUrl,
    data: data
  }).then(function(response){
    resultState.state = 'success';
    resultState.data = response.data;
  }).catch(function(err) {
    console.log('err', err);
    resultState.state = 'error';
    resultState.data = err.response?.data? err.response.data.message : err.message;
    window.toastr.error('Email or Password is not matched');
  })

  return resultState;
}

export const newAgent_Api = async (data) => {
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/users/register`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const editAgent_Api = async (id, data) => {
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/users/${id}`;
  const token = getJWTToken();
  await axios.put(apiUrl, data, {headers: {Authorization: token}}).then(function (response) {
    console.log(response)
    resultData.state = 'success';
    resultData.data = response.data;
  }).catch(function(err) {
    resultData.state = 'error';
    resultData.data = err.message;
  })
  return resultData;
}


export const getAllAgents_Api = async () => {
  const tokenData = getJWTToken();
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/users/agents`;
  await axios({
      method: 'get',
      url: apiUrl,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      console.log(err);
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}


export const getAllAirportsApi = async () => {
    const tokenData = getJWTToken();  
    let resultData = {state: '', data: ''};
    let apiUrl = `${BASE_BACKEND_URL}/data/getAllAirports`;
    await axios({
        method: 'get',
        url: apiUrl,
        headers: {Authorization: tokenData}
      }).then(function(response) {
        console.log(response)
        resultData.state = 'success';
        resultData.data = response.data;
      }).catch(function(err) {
        resultData.state = 'error';
        resultData.data = err.message;
      })
      return resultData;
}

export const newAirport_Api = async (data) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/newAirport`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const editAirport_Api = async (data) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/editAirPort`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const deleteAirport_Api = async (id) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/removeAirport`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: {id: id},
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const getAllAirlinesApi = async () => {
    const tokenData = getJWTToken();  
    let resultData = {state: '', data: ''};
    let apiUrl = `${BASE_BACKEND_URL}/data/getAllAirlines`;
    await axios({
        method: 'get',
        url: apiUrl,
        headers: {Authorization: tokenData}
      }).then(function(response) {
        console.log(response)
        resultData.state = 'success';
        resultData.data = response.data;
      }).catch(function(err) {
        resultData.state = 'error';
        resultData.data = err.message;
      })
      return resultData;
}

export const removeTicketDataApi = async (id) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/removeTicketData`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: {id: id},
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const getAllTicketsApi = async () => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/getAllTickets`;
  await axios({
      method: 'get',
      url: apiUrl,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const getTicketData_Api = async (id) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/getTicketData`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: {id: id},
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const uploadTicketMedias_Api = async (data) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/uploadTicketMedias`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const deleteTicketMedia_Api = async (id, mediaId) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/deleteTicketMedia`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: {id: id, mediaId: mediaId},
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const editAirline_Api = async (data) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/editAirline`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const newAirline_Api = async (data) => {
  const tokenData = getJWTToken();  
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/newAirline`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: data,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const deleteAirline_Api = async (id) => {
  const tokenData = getJWTToken();
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/removeAirline`;
  await axios({
      method: 'post',
      url: apiUrl,
      data: {id: id},
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}

export const getAllZendeskTicketsApi = async () => {
  const tokenData = getJWTToken();
  let resultData = {state: '', data: ''};
  let apiUrl = `${BASE_BACKEND_URL}/data/getZendeskTickets`;
  await axios({
      method: 'get',
      url: apiUrl,
      headers: {Authorization: tokenData}
    }).then(function(response) {
      console.log(response)
      resultData.state = 'success';
      resultData.data = response.data;
    }).catch(function(err) {
      resultData.state = 'error';
      resultData.data = err.message;
    })
    return resultData;
}