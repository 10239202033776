import React, { useEffect, useState } from 'react';
import Team from "./scenes/team";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Contacts from "./scenes/contacts";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/Pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";
import Sidebar from './scenes/global/Sidebar';
import { Box } from '@mui/system';
import AirPorts from './scenes/Airports';
import Airlines from './scenes/Airlines';
import Tickets from './scenes/Tickets';
import {TicketModal} from './scenes/Tickets/TicketModal';
import { Login } from './scenes/Auth/Login';
import { currentUser } from './Apiprovider/Apiprovider';
import { useDispatch, useSelector } from 'react-redux';
import { userData_Store } from './store/actions/mainAction';
// const AuthContext = React.createContext(null);
let mobileScreenDetect = false

const authPath = [
  "/login",
]

function App() {
  const dispatch = useDispatch();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(!mobileScreenDetect);  
  const location = useLocation();
  const userData = useSelector(store=>store.userData);
  const isLoggedIn = useSelector(store => store.isLoggedIn);
  console.log('user DataTransfer', userData);
  // const userData = localStorage.getItem('userData');
  const [token, setToken] = React.useState(null);
  
// useEffect(() => {
//   console.log('userLoaing');
// }, []);

//   const handleLogin = async () => {
    
//     console.log(userData);
//     if(userData){
//       const tmpUser = JSON.parse(userData);
//        const loginRes = await currentUser(tmpUser);
//       console.log('checkData', loginRes);
//       if(loginRes.state !== 'success'){
//         localStorage.setItem('userData', JSON.stringify(loginRes.data));
//         dispatch(userData_Store(loginRes.data));
//         window.location.href = '/login';  
//       }
//       setToken(loginRes.data.token);
//     }else{
//       console.log('redirect check', userData);
//         window.location.href = '/login';
//     }
    
//   };

//   const handleLogout = () => {
//     setToken(null);
//     localStorage.setItem('userData', null);
//   };

//   const value = {
//     token,
//     onLogin: handleLogin,
//     onLogout: handleLogout,
//   };

  useEffect(() => {
    console.log(`The current route is ${location.pathname}`);
    checkLogin()
  }, [location]);

  const checkLogin = async () => {
    const userData = localStorage.getItem('userData');
    console.log('userDataCheck', JSON.parse(userData), userData, window.location);
    if(!authPath.includes(window.location.pathname)){
      if(userData){
        const tmpUser = JSON.parse(userData);
        const loginRes = await currentUser(tmpUser);
        console.log('checkData', loginRes);
        
        if(loginRes.state !== 'success'){
          localStorage.setItem('userData', JSON.stringify(loginRes.data));
          dispatch(userData_Store(loginRes.data));
          window.location.href = '/login';  
        }else{
          setToken(loginRes.data.token);
        }

      }else{
        console.log('redirect check', userData);
        window.location.href = '/login';
      }
    }
    
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className='content' style={{ display: 'flex' }}>
            
            <Box flexGrow={1}>
              <Routes >
                <Route path='/login' element={<Login />} />
                {isLoggedIn && <>
                  <Route path="/" element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <Dashboard />
                  </main>
                  
                  } />
                
                <Route path='/airports' element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <AirPorts />
                  </main>
                  
                  } />
                <Route path='/airlines' element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <Airlines />
                  </main>
                }/>
                <Route path='/tickets' element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <Tickets />
                  </main>
                  } />
                <Route path='/ticket/:id' element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <TicketModal />
                  </main>
                  } />
                <Route path="/team" element={
                  <main  className='content' style={{ display: 'flex' }}>
                    <Sidebar isSidebar={isSidebar} />
                    <Team />
                  </main>
                  } />
                </>}
                
                {/* <Route path="/contacts" element={<Contacts />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/form" element={<Form />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/geography" element={<Geography />} /> */}
              </Routes>
            </Box>
          
          </main>
          
        </div>
      </ThemeProvider>
      
    </ColorModeContext.Provider>
  );
}


export default App;
