import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { editAgent_Api, newAgent_Api } from "../../Apiprovider/Apiprovider";

export const AgentModal = ({ open, handleClose, agentData }) => {
  const [userId, setUserId] = useState('');
  const [title, setTitle] = useState('Edit User');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isPassword, setIsPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  useEffect(() => {
    if (agentData == null) {
      setTitle('New User')
      setUserId('')
      setFirstName('');
      setLastName('');
      setEmail('')
    } else {
      setTitle('Edit User')
      setUserId(agentData.id);
      setFirstName(agentData.firstName);
      setLastName(agentData.lastName);
      setEmail(agentData.email);
    }
  }, [agentData])

  const handleChange = (event) => {
    setIsPassword(event.target.checked);
  };

  const onSaveBtnFunc = async () => {
    if (userId == '') {
      let payload = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: newPassword,
        role: 2,
      }
      await newAgent_Api(payload);
      handleClose(true);
    } else {
      let payload = {
        email: email,
        firstName: firstName,
        lastName: lastName,
      }
      if (isPassword) {
        payload.password = newPassword;
      }
      await editAgent_Api(userId, payload)
      handleClose(true);
    }

  }
  const isSubmitable = () => {
    let clickable = true;
    if (userId == '') {
      clickable = !(firstName != '' && lastName != '' && email != '' && newPassword != '');
    } else {
      if (isPassword) {
        clickable = !(firstName != '' && lastName != '' && email != '' && newPassword != '');
      } else {
        clickable = !(firstName != '' && lastName != '' && email != '');
      }
    }

    return clickable;
  }
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Box padding={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="First Name"
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Last Name"
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="email"
                label="Email"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </Grid>
            {userId == '' &&
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => { setNewPassword(e.target.value) }}
                />
              </Grid>
            }
            {userId != '' &&
              <Grid item xs={12}>
                <FormControlLabel
                  label="Set New Password"
                  control={
                    <Checkbox
                      checked={isPassword}
                      onChange={handleChange}
                    />
                  }
                />
                {isPassword && (<TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => { setNewPassword(e.target.value) }}
                />)}
              </Grid>
            }
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => handleClose(false)}>Cancel</Button>
        <Button disabled={isSubmitable()} variant="contained" color="success" onClick={() => onSaveBtnFunc()} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}