import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { getAllTicketsApi, removeTicketDataApi } from '../../Apiprovider/Apiprovider'
import { TicketModal } from "./TicketModal";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const Tickets = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [ticketsData, setTicketsData] = useState([])

  useEffect(() => {
    loadTicketsData();
  }, [])
  const loadTicketsData = async () => {
    const apiRes = await getAllTicketsApi();
    console.log(apiRes);
    if (apiRes.state !== 'success') return;
    const resPorts = apiRes.data;
    setTicketsData(resPorts);
  }
  const onTicketClick = (ticketsData) => {
    console.log(ticketsData);
    setSelectedTicket(ticketsData);
    navigate(`/ticket/${ticketsData.id}`)
    // setOpenModal(true);
  }

  const removeTicketItem = async (id) => {
    Swal.fire({
      title: "Do you want to remove this Ticket?",
      showCancelButton: true,
      confirmButtonText: "Yes",
  }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          const ticketRes = await removeTicketDataApi(id);
          if (ticketRes.state !== 'success') return;
          loadTicketsData();
      } else {
          // Swal.fire("Changes are not saved", "", "info");
          console.log('cancelled');
      }
  });
  }

  const columns = [
    { field: "ticketIdNum", headerName: "Identifier", flex: 1 },
    {
      field: 'created_at', headerName: 'Created At', flex: 1,
      renderCell: ({ row }) => {
        const dateVal = new Date(row.created_at);
        return `${dateVal.toLocaleDateString()} ${dateVal.toLocaleTimeString()}`;
      }
    },
    {
      field: "updated_at",
      headerName: "Action",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box sx={{textAlign: 'right', width: '100%', height: '100%' }}>
            <Button variant="contained" color="success" onClick={() => { onTicketClick(row) }}>
              View
            </Button>
            <Button onClick={() => {removeTicketItem(row.id)}} variant="contained" color="error">
              Delete
            </Button>
          </Box>

        );
      },
    },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <Box m="20px" width={'100%'}>
      <Header title="Tickets" subtitle="Managing the Tickets" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={ticketsData} columns={columns} />
      </Box>
      {/* <TicketModal
        modalData={selectedTicket}
        open={openModal}
        handleClose={handleCloseModal}
      /> */}
    </Box>
  );
};

export default Tickets;
