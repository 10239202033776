import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { deleteAirline_Api, getAllAirlinesApi } from "../../Apiprovider/Apiprovider"
import { AirlineModal } from './AirlineModal';
import Swal from 'sweetalert2'

const Airlines = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [airLine, setAirLine] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [airlineData, setAirlineData] = useState(null);
  const handleModalClose = (pageReloading) => {
    setModalOpen(false);
    setAirlineData(null);
    if (pageReloading) {
      loadAirLinesData();
    }
  }

  useEffect(() => {
    loadAirLinesData()
  }, [])

  const loadAirLinesData = async () => {
    const apiRes = await getAllAirlinesApi();
    console.log(apiRes);
    if (apiRes.state !== 'success') return;
    const resPorts = apiRes.data;
    setAirLine(resPorts);
  }

  const deleteAirline = async (id) => {
    Swal.fire({
      title: "Do you want to remove this Airline?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await deleteAirline_Api(id);
        loadAirLinesData();
      } else {
        // Swal.fire("Changes are not saved", "", "info");
        console.log('cancelled');
      }
    });

  }

  const columns = [
    {
      field: "logo",
      headerName: "Country",
      headerAlign: "left",
      align: "left",
      renderCell: ({ row: { logo } }) => {
        return (
          <Box
            m="0 auto"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#fff"
          >
            <img src={logo} style={{ height: '100%', width: 'auto' }} alt="logo" />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "code",
      flex: 1,
      headerName: "Code",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "id",
      flex: 1,
      headerName: "Action",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ width: '100%', height: '100%', textAlign: 'right' }}>
            <Button variant="contained" color="success" onClick={() => {
              console.log(row)
              setAirlineData(row);
              setModalOpen(true);
            }}>
              Edit
            </Button>
            <Button onClick={() => { deleteAirline(row.id) }} variant="contained" color="error">
              Delete
            </Button>
          </Box>

        );
      },
    },
  ];

  const openNewAirlineModal = () => {
    setAirlineData(null);
    setModalOpen(true);
  }

  return (
    <Box m="20px" width={'100%'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Header title="Airlines" subtitle="See the Airlines" />
        <Button onClick={() => openNewAirlineModal()} variant='contained' color='success' sx={{ fontWeight: 'bold' }}>New Airline</Button>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "none !important",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={airLine} columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
      <AirlineModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        airline={airlineData}
      />
    </Box>
  );
};

export default Airlines;
