import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { useEffect, useState } from "react"
import { editAirline_Api, newAirline_Api } from "../../Apiprovider/Apiprovider"

export const AirlineModal = ({ modalOpen, handleModalClose, airline }) => {
  const [modalText, setModalText] = useState('Airline Edit')
  const [airlineId, setAirlineId] = useState('')
  const [name, setName] = useState('');
  const [code, setCode] = useState('')
  const [isLowCost, setIsLowCost] = useState(0);
  const [logoPath, setLogoPath] = useState('');

  useEffect(() => {
    if (airline == null) {
      setModalText('New Airline');
      setAirlineId('')
      setName('');
      setIsLowCost('');
      setCode('');
      setLogoPath('');
    } else {
      setModalText('Airline Edit');
      setAirlineId(airline?.id)
      setName(airline?.name);
      setIsLowCost(airline?.is_lowcost);
      setCode(airline?.code);
      setLogoPath(airline?.logo);
    }
  }, [airline])

  const saveBtnFunc = async () => {
    if (airlineId == '') {
      const newPayload = {
        name: name,
        code: code,
        logo: logoPath,
        is_lowcost: isLowCost
      };
      await newAirline_Api(newPayload);
    } else {
      const editPayload = {
        id: airlineId,
        name: name,
        code: code,
        logo: logoPath,
        is_lowcost: isLowCost
      }
      await editAirline_Api(editPayload);
    }
    handleModalClose(true);
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={() => handleModalClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {modalText}
      </DialogTitle>
      <DialogContent >
        <Box padding={1}>
          <Stack direction={'row'} spacing={1}>
            <Box>
              <img src={logoPath} alt="logo Image" style={{ height: '200px', width: '200px' }} />
            </Box>
            <Box>
              <TextField
                fullWidth
                label={'Name'}
                onChange={(e) => { setName(e.target.value); }}
                value={name}
              />
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                label={'Code'}
                value={code}
                onChange={(e) => { setCode(e.target.value); }}
              />
              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                label={'Is Low Cost'}
                value={isLowCost}
                onChange={(e) => { setIsLowCost(e.target.value); }}
              />

              <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                label={'Logo Path'}
                value={logoPath}
                onChange={(e) => { setLogoPath(e.target.value); }}
              />
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" onClick={() => handleModalClose(false)}>Cancel</Button>
        <Button disabled={!name && !code} color="success" variant="contained" onClick={() => saveBtnFunc()} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}