import { USERDATA, ISLOGGEDIN } from "../constants/main";

export const mainStore = (state = {}, action) => {
    switch(action.type) {
        case USERDATA: {
            return {
                ...state,
                userData: action.payload
            }
        }

        case ISLOGGEDIN: {
            return {
                ...state,
                isLoggedIn: action.payload
            }
        }

        
        default: {
			return { ...state };
		}
    }
}