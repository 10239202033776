import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, ListItemText, Card, Divider, useTheme, TextField } from "@mui/material";
import { tokens } from "../../theme";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MuiFileInput } from 'mui-file-input'
import { BASE_BACKEND_URL, getTicketData_Api, deleteTicketMedia_Api, uploadTicketMedias_Api } from '../../Apiprovider/Apiprovider';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'

export const TicketModal = () => {
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [medias, setMedias] = useState([]);
    const [modalData, setModalData] = useState(null);
    useEffect(() => {
        if (modalData != null) {
            // setMedias(modalData.medias);
            const tmpMedias = modalData.medias;
            let tmpMediaList = [];
            tmpMedias.map(mdItem => {
                const fileName = mdItem.path.split('/')[1];
                const filePath = `${BASE_BACKEND_URL}/${mdItem.path}`;
                tmpMediaList.push({
                    id: mdItem._id,
                    name: fileName,
                    path: filePath
                })
            });
            setMedias(tmpMediaList);
        }
    }, [modalData]);

    useEffect(() => {
        console.log(params.id);
        loadTicketData();
    }, []);

    const loadTicketData = async () => {
        const ticketRes = await getTicketData_Api(params.id);
        if (ticketRes.state !== 'success') return;
        console.log(ticketRes);
        setModalData(ticketRes.data);
    }
    const openMediaFile = (path) => {
        window.open(path, '_blank', 'toolbar=0,location=0,menubar=0');
    }
    const deletMediaFile = async (mediaItem) => {
        // console.log(mediaItem);
        Swal.fire({
            title: "Do you want to remove this Media?",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const mediaRes = await deleteTicketMedia_Api(modalData.id, mediaItem.id);
                if (mediaRes.state !== 'success') return;
                setModalData(mediaRes.data);
            } else {
                // Swal.fire("Changes are not saved", "", "info");
                console.log('cancelled');
            }
        });


    }
    const handleClose = () => {
        navigate('/tickets');
    }
    const [newFile, setNewFile] = useState(null);
    const changeFilepicker = (newValue) => {
        console.log(newValue);
        setNewFile(newValue);
    }
    const onUploadNewMedia = async () => {
        const formData = new FormData();
        formData.append('documents', newFile);
        formData.append('id', modalData.id);
        const uploadRes = await uploadTicketMedias_Api(formData);
        if (uploadRes.state !== 'success') return;
        setModalData(uploadRes.data);
        setNewFile(null);
    }
    return (
        <Dialog
            fullScreen
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                backgroundColor: colors.blueAccent[700],
                zIndex: '1010'
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: "space-between", borderBottom: '1px solid' }}>
                <Typography variant="h4" fontWeight="bold">Ticket Data</Typography>
                <IconButton onClick={handleClose} color="secondary">
                    <HighlightOffIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Box padding={2} border={'1px solid'} borderRadius={'5px'}>
                                <div dangerouslySetInnerHTML={{ __html: modalData?.ticket_body }} />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display={'flex'} marginBottom={1}>
                                <MuiFileInput fullWidth size='small' label="Add New File" value={newFile} placeholder='AddNew File' onChange={changeFilepicker} />
                                <Button disabled={newFile == null} onClick={() => { onUploadNewMedia() }} variant='contained' color='success' >Upload</Button>
                            </Box>
                            <List>
                                {medias.map((mediaItem, key) =>
                                    <ListItem key={key} sx={{ border: '1px solid', borderRadius: '3px' }}>
                                        <ListItemText primary={mediaItem.name} />
                                        <IconButton onClick={() => openMediaFile(mediaItem.path)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => deletMediaFile(mediaItem)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>

                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}