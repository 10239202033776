import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material"
import { Box } from "@mui/system";
import { useEffect, useState } from "react"
import { editAirport_Api, newAirport_Api } from "../../Apiprovider/Apiprovider";

export const AirportModal = ({ open, handleClose, airport }) => {
	const [modalTitle, setModalTitle] = useState('Edit AirPort');
	const [airPortId, setAirportId] = useState('');
	const [name, setName] = useState('');
	const [alterName, setAlterName] = useState('');
	const [country, setCountry] = useState('');
	const [iataCode, setIataCode] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [localCode, setLocalCode] = useState('');
	const [elevationft, setElevationft] = useState('');
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');
	const [timezone, setTimezone] = useState('');
	const [icaoCode, setIcaoCode] = useState('');
	const [order, setOrder] = useState('');
	useEffect(() => {
		if (airport !== null) {
			setAirportId(airport.id);
			setModalTitle('Edit AirPort')
			setName(airport.name);
			setAlterName(airport?.alertnetive);
			setCountry(airport.country);
			setIataCode(airport?.iata_code);
			setCity(airport?.city);
			setState(airport?.state);
			setLocalCode(airport?.local_code);
			setElevationft(airport?.elevation_ft);
			setLat(airport?.latitude_deg);
			setLng(airport?.longitude_deg);
			setTimezone(airport?.time_zone);
			setIcaoCode(airport?.icao_code);
			setOrder(airport?.order);
		} else {
			setAirportId('');
			setModalTitle('New AirPort')
			setName('');
			setAlterName('');
			setCountry('');
			setIataCode('');
			setCity('');
			setState('');
			setLocalCode('');
			setElevationft('');
			setLat('');
			setLng('');
			setTimezone('');
			setIcaoCode('');
			setOrder('');
		}
	}, [airport])

	const onSaveBtnFunc = async () => {
		if (airPortId !== '') {
			const payload = {
				id: airPortId,
				name: name,
				city: city,
				country: country,
				elevation_ft: elevationft,
				iata_code: iataCode,
				icao_code: icaoCode,
				latitude_deg: lat,
				local_code: localCode,
				longitude_deg: lng,
				alertnetive: alterName,
				order: order,
				state: state,
				time_zone: timezone,
			}
			await editAirport_Api(payload);
			handleClose(true)
		} else {
			const payload = {
				name: name,
				city: city,
				country: country,
				elevation_ft: elevationft,
				iata_code: iataCode,
				icao_code: icaoCode,
				latitude_deg: lat,
				local_code: localCode,
				longitude_deg: lng,
				alertnetive: alterName,
				order: order,
				state: state,
				time_zone: timezone,
			}
			await newAirport_Api(payload);
			handleClose(true)
		}
	}

	const checkSubmitable = () => {
		return !(name !== '' && country !== '' && iataCode !== '' && lat !== '' && lng !== '' && elevationft !== '' && timezone !== '' && icaoCode !== '')
	}
	return (
		<Dialog
			open={open}
			onClose={() => handleClose(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{modalTitle}
			</DialogTitle>
			<DialogContent>
				<Box padding={3}>
					<Grid container spacing={2}>
						<Grid xs={12} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Name'}
								value={name}
								onChange={(e) => { setName(e.target.value) }}
							/>
						</Grid>
						<Grid xs={12} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Alternative Name'}
								value={alterName}
								onChange={(e) => { setAlterName(e.target.value) }}
							/>
						</Grid>
						<Grid xs={8} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Country'}
								value={country}
								onChange={(e) => { setCountry(e.target.value) }}
							/>
						</Grid>
						<Grid xs={4} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Iata Code'}
								value={iataCode}
								onChange={(e) => { setIataCode(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'City'}
								value={city}
								onChange={(e) => { setCity(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'State'}
								value={state}
								onChange={(e) => { setState(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Local Code'}
								value={localCode}
								onChange={(e) => { setLocalCode(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Elevation FT'}
								value={elevationft}
								onChange={(e) => { setElevationft(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Latitude Degree'}
								value={lat}
								onChange={(e) => { setLat(e.target.value) }}
							/>
						</Grid>
						<Grid xs={6} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Longitude Degree'}
								value={lng}
								onChange={(e) => { setLng(e.target.value) }}
							/>
						</Grid>
						<Grid xs={4} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Timezone'}
								value={timezone}
								onChange={(e) => { setTimezone(e.target.value) }}
							/>
						</Grid>
						<Grid xs={4} padding={1}>
							<TextField
								size="small"
								fullWidth
								label={'Icao Code'}
								value={icaoCode}
								onChange={(e) => { setIcaoCode(e.target.value) }}
							/>
						</Grid>
						<Grid xs={4} padding={1}>
							<TextField
								size="small"
								fullWidth
								type="number"
								label={'Order'}
								value={order}
								onChange={(e) => { setOrder(e.target.value) }}
							/>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="error" variant="contained" onClick={() => handleClose(false)}>Cancel</Button>
				<Button disabled={checkSubmitable()} color="success" variant="contained" onClick={(e) => { onSaveBtnFunc() }} autoFocus>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}